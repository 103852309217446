/*
*
* Material Parallax
*/

.parallax-container {
  position: relative;
  overflow: hidden;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
}

.ipad, .iphone {
  .material-parallax {
    background-attachment: scroll !important;
  }
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  transform: translate3d(-50%, 0, 0);
  max-width: inherit;
  @include media-breakpoint-up(xl) {
    max-width: 100%;
  }
}

.parallax-content {
  position: relative;
  z-index: 1;
  > * {
    position: relative;
    z-index: 1;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, .6);
    z-index: 0;
  }
}

.bg-position-mobile {
  background-position: -500px;
}