// Spacer
@mixin spacing($x, $y : $x) {
  position: relative;
  transform: translateY(-$y);
  margin-bottom: -$y;

  > * {
    margin-top: $y;
  }

  > *:not(:last-child) {
    margin-right: $x;
  }
}