//
// Page Loaders
// --------------------------------------------------

// Base styles
// -------------------------

.page-loader-variant-1 {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  @include display-flex();
  @include justify-content(center);
  @include align-items(center);
  padding: $page-loader-v-1-padding;
  z-index: 9999999;
  background: $page-loader-v-1-bg;
  transition: .3s all ease;

  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &.ending {
    display: none;
  }

  .page-loader-body {
    margin-top: 40px;
    text-align: center;
  }
}

[data-x-mode="design-mode"] {
  .page-loader-variant-1 {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
}

#spinningSquaresG {
  position: relative;
  width: 150px;
  height: 18px;
  margin: auto;
}

.spinningSquaresG {
  position: absolute;
  top: 0;
  background-color: rgb(255, 255, 255);
  width: 18px;
  height: 18px;
  animation-name: bounce_spinningSquaresG;
  -o-animation-name: bounce_spinningSquaresG;
  -ms-animation-name: bounce_spinningSquaresG;
  -webkit-animation-name: bounce_spinningSquaresG;
  -moz-animation-name: bounce_spinningSquaresG;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  transform: scale(.3);
  -o-transform: scale(.3);
  -ms-transform: scale(.3);
  -webkit-transform: scale(.3);
  -moz-transform: scale(.3);
}

#spinningSquaresG_1 {
  left: 0;
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}

#spinningSquaresG_2 {
  left: 19px;
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}

#spinningSquaresG_3 {
  left: 37px;
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}

#spinningSquaresG_4 {
  left: 56px;
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}

#spinningSquaresG_5 {
  left: 75px;
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}

#spinningSquaresG_6 {
  left: 94px;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}

#spinningSquaresG_7 {
  left: 112px;
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}

#spinningSquaresG_8 {
  left: 131px;
  animation-delay: 1.64s;
  -o-animation-delay: 1.64s;
  -ms-animation-delay: 1.64s;
  -webkit-animation-delay: 1.64s;
  -moz-animation-delay: 1.64s;
}

@keyframes bounce_spinningSquaresG {
  0% {
    transform: scale(1);
    background-color: rgb(255, 255, 255);
  }

  100% {
    transform: scale(.3) rotate(90deg);
    background-color: rgb(0, 0, 0);
  }
}

@-o-keyframes bounce_spinningSquaresG {
  0% {
    -o-transform: scale(1);
    background-color: rgb(255, 255, 255);
  }

  100% {
    -o-transform: scale(.3) rotate(90deg);
    background-color: rgb(0, 0, 0);
  }
}

@-webkit-keyframes bounce_spinningSquaresG {
  0% {
    -webkit-transform: scale(1);
    background-color: rgb(255, 255, 255);
  }

  100% {
    -webkit-transform: scale(.3) rotate(90deg);
    background-color: rgb(0, 0, 0);
  }
}

@-moz-keyframes bounce_spinningSquaresG {
  0% {
    -moz-transform: scale(1);
    background-color: rgb(255, 255, 255);
  }

  100% {
    -moz-transform: scale(.3) rotate(90deg);
    background-color: rgb(0, 0, 0);
  }
}
