//
// Page title
// --------------------------------------------------
$page-title-spacing: 1.2em;

.page-title {
  text-align: center;
  * { letter-spacing: 0; }

  .page-title-inner {
    position: relative;
    display: inline-block;
  }

  .page-title-left,
  .page-title-right {
    position: absolute;
    top: 50%;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;

    * {
      display: inline;
      white-space: nowrap;
      color: rgba($white, .1);
    }
  }

  .page-title-left {
    left: 0;
    text-align: right;
    transform: translate(-100%, -50%);

    * {
      padding-right: .5em;
    }
  }

  .page-title-right {
    right: 0;
    text-align: left;
    transform: translate(100%, -50%);

    * {
      padding-left: .5em;
    }
  }
  h2  {
    font-style: normal;
  }
}

.page-title-wrap {
  position: relative;
  background-size: cover;
  background-position: center;
  > * {
    position: relative;
    z-index: 1;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, .45);  
  }
}

@include media-breakpoint-up(md) {
  .page-title {
    text-align: left;

    .page-title-left {
      * {
        padding-right: $page-title-spacing;
      }
    }

    .page-title-right {
      * {
        padding-left: $page-title-spacing;
      }
    }
  }
}