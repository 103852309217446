//
// Functions
// --------------------------------------------------


@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function rem($px-size) {
  @return str-replace(#{($px-size/100)}, px, rem);
}

@function remove($list, $value, $recursive: false) {
  $result: ();

  @for $i from 1 through length($list) {
    @if type-of(nth($list, $i)) == list and $recursive {
      $result: append($result, remove(nth($list, $i), $value, $recursive));
    } @else if nth($list, $i) != $value {
      $result: append($result, nth($list, $i));
    }
  }

  @return $result;
}

@function sort($list) {
  $sortedlist: ();
  @while length($list) > 0 {
    $value: nth($list, 1);
    @each $item in $list {
      @if $item < $value {
        $value: $item;
      }
    }
    $sortedlist: append($sortedlist, $value, 'space');
    $list: remove($list, $value);
  }
  @return $sortedlist;
}

@function average($list) {
  $sum: 0;

  @each $i in $list {
    $sum: $sum + $i;
  }

  @return $sum / length($list);
}

@function headings() {
  @return unquote("h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4,.h5, .h6, .h7");
}

@function cols($resolution) {
  $selector: ();
  @each $j in 1, 2, 3, 4, 6, 12 {
    $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n + #{(12/$j)+1})", comma);
  }

  $selector: append($selector, "> .col-#{$resolution}-8.col-#{$resolution}-preffix-2");

  @return $selector;
}

@function cells($resolution) {
  $selector: ();
  @each $j in 1, 2, 3, 4, 6, 12 {
    $selector: append($selector, "> .cell-#{$resolution}-#{$j}:nth-child(n + #{(12/$j)+1})", comma);
  }

  $selector: append($selector, "> .cell-#{$resolution}-8.cell-#{$resolution}-preffix-2");

  @return $selector;
}

@function cols-full($resolution) {
  $selector: ();
  @for $j from 1 through 12 {
    $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n)", comma);
  }

  @return $selector;
}
@function cells-full($resolution) {
  $selector: ();
  @for $j from 1 through 12 {
    $selector: append($selector, "> .cell-#{$resolution}-#{$j}:nth-child(n)", comma);
  }

  @return $selector;
}

//
// Remove the unit of a length
// @param {Number} $number - Number to remove unit from
// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}